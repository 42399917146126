<template>
	<component
		:is="isButton ? 'button' : 'span'"
		class="button button--no-style chevron chevron-down"
		:class="{ 'chevron-ex' : expanded }"
		@click="$emit('click')"
	>
		<span class="visually-hidden">expand</span>
	</component>
</template>

<script>
	export default {
		name: 'CExpandToggleButton',
		props: {
			expanded: {
				type: Boolean,
				required: true
			},
			isButton: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				activeHeader: null
			};
		}
	};
</script>

<style lang="scss" src="@cognitoforms/styles/website/chevron.scss"></style>
